import './style.css'
import { FooterLogo, Telegram, VK, Whatsapp } from '../../svg'
import { Link } from 'react-router-dom'

export const Footer = () => {
    return (
        <div className='mainFooter'>
            <div className='footer'>
                <div className='footerLeft'>
                    <FooterLogo />
                    <div className='footerApps'>
                        <VK />
                        <Telegram />
                        <Whatsapp />
                    </div>
                </div>
                <div className='footerColumn'>
                    <h3>НАША МИССИЯ</h3>
                    <p>Наша основная специализация — делать ваш бизнес заметнее в интернете, разрабатывая <br />
                        и продвигая ваш сайт с помощью SEO-оптимизации, контекстной и таргетированной рекламы. <br /><br />
                        А ещё делать его замечательным в глазах потенциальных клиентов с услугой управления репутацией!
                    </p>
                </div>
                <div className='footerColumn'>
                    <h3>КОНТАКТЫ</h3>
                    <p>Ереван, 26а улица Мовсеса Хоренаци</p>
                    <p>Телефон: <a href="tel:+74951452970">+7 495 145-29-70</a></p>
                    <p>Эл. почта: <a href='mailto:justcodedevcompany@gmail.com'> justcodedevcompany@gmail.com</a></p>
                </div>
            </div>
            <div className='footerBottom'>
                <span>Digital-агентство JustCode Development Company © 2018 — 2023. Все права защищены</span>
                <div className='footerNavigation'>
                    <Link>Услуги</Link>
                    <Link>Кейсы</Link>
                    <Link>Команда</Link>
                    <Link>Контакты</Link>
                </div>
            </div>
        </div>
    )
}