import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  BrowserRouter,

} from 'react-router-dom';
import './App.css';

import HomeScreen from './pages/home/HomeScreen'

function App() {
  return (
    
      <Router>
        <Routes>
          <Route path='/' element={<HomeScreen />} />
          {/* <Route path='/' element={<CheckLogin onLogout={handleLogout} onLogin={handleLogin} token={token} />} />
          <Route path="/devices" element={<DevicesScreen onLogout={handleLogout} token={token} />} />
          <Route path="/devices/:id" exact={true} element={<DeviceDetailsScreen token={token} onLogout={handleLogout} />} />
          <Route path="/clients" element={<ClientScreen token={token} onLogout={handleLogout} />} />
          <Route path="/settings" element={<SettingsScreen token={token} onLogout={handleLogout} />} />
          <Route path="/clients-details/:id" exact={true} element={<ClientsDetails token={token} onLogout={handleLogout} />} />
          <Route path="/admins" element={<Admins token={token} onLogout={handleLogout} />} />
          <Route path="/add-new-user" element={<AddNewUser token={token} onLogout={handleLogout} />} />
          <Route path="/edit-user/:id/:name/:login/:isAdmin/:isActive" exact={true} element={<EditUser onLogout={handleLogout} token={token} />} /> */}
        </Routes>
      </Router>
  
  );
}

export default App;
