import './MobileProject.scss'

const data = [
    {
        id: 1, name: 'BandRate', img: 'https://cdn-edge.kwork.ru/files/portfolio/t0_r/25/80df8903de2827bc941939a9ebe46c6fc8971c0a-1667996125.webp'
    },
    {
        id: 2, name: 'BandRate', img: 'https://cdn-edge.kwork.ru/files/portfolio/t0_r/25/80df8903de2827bc941939a9ebe46c6fc8971c0a-1667996125.webp'
    },
    {
        id: 3, name: 'BandRate', img: 'https://cdn-edge.kwork.ru/files/portfolio/t0_r/25/80df8903de2827bc941939a9ebe46c6fc8971c0a-1667996125.webp'
    },
    {
        id: 4, name: 'BandRate', img: 'https://cdn-edge.kwork.ru/files/portfolio/t0_r/25/80df8903de2827bc941939a9ebe46c6fc8971c0a-1667996125.webp'
    },
]


export default function App() {
    return (
        <div className='mobileProject_container' >
            <h2>МОБИЛЬНЫЕ ПРИЛОЖЕНИЯ</h2>
            <div className='mobileProject_block' >
                {data.map((value, index) => {
                    return (
                        <div key={index} className='mob_mini_block' >
                            <p>{value.name}</p>
                            <img src={value.img} alt='img' />
                        </div>
                    )
                })}
            </div>
        </div>
    )
}