import './HeaderBlock.scss'
import NavBar from '../../navbar/Navbar'
import BannerBlock from '../banner/BannerScreen'

export default function App() {
    return (
        <div className="header_container" >
            <NavBar />
            <BannerBlock />
        </div>
    )
}