import './MainBlock.scss'
import HelpsBlock from './helps/HelpsBlock'
import MobileProject from './mobileprojects/MobileProject'
import { Websites } from './websites'
import { TrustUs } from './trustUs'
import { OurTeam } from './ourTeam'
import { JoinUs } from './joinUs'
import { GoogleMaps } from './googleMaps'
import { Footer } from '../footer'

export default function App() {
    return (
        <div className='main_container' >
            <div className='main_center' >
                <HelpsBlock />
                <MobileProject />
                <Websites />
            </div>
            <TrustUs />
            <div className='main_center' >
                <OurTeam />
            </div>
            <JoinUs />
            <GoogleMaps />
            <div className='main_center' >
                <Footer />
            </div>
        </div>
    )
}