import { FooterLogo } from '../svg'
import './Navbar.scss'

export default function App() {
    return (
        <div className='navbar_container'>
            <div className='navbar_center_block' >
                <div className='logo_block' >
                    <FooterLogo />
                    <div className='navbar_list' >
                        <ul>
                            <li>
                                <a style={{ color: '#FF6501' }} >
                                    Услуги
                                </a>
                            </li>
                            <li>
                                <a>
                                    Кейсы
                                </a>
                            </li>
                            <li>
                                <a>
                                    Команда
                                </a>
                            </li>
                            <li>
                                <a>
                                    Контакты
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <button>
                    Получить консультацию
                </button>
            </div>
        </div>
    )
}