import './style.css'

export const OurTeam = () => {
    const team = [
        {
            image: 'artyom-removebg-preview.png',
            name: 'Артем',
            surname: 'Акобджанян',
            profession: 'CEO'
        },
        {
            image: 'vito-removebg-preview(1).png',
            name: 'Виталий',
            surname: 'Акобджанян',
            profession: 'Проект-менеджер'
        },
        {
            image: 'noro.png',
            name: 'Норайр',
            surname: 'Асоян',
            profession: 'UI/UIX Дизайнер'
        },
        {
            image: 'web1.png',
            name: 'Лианна',
            surname: 'Акобян',
            profession: 'Manual QA engineer'
        },
        {
            image: 'web1.png',
            name: 'Мехак',
            surname: 'Погосян',
            profession: 'Android/ios разработчик'
        },
        {
            image: 'shoxik-removebg-preview.png',
            name: 'Шогик',
            surname: 'Галоян',
            profession: 'Android/ios разработчик'
        },
        {
            image: 'Levon-removebg-preview.png',
            name: 'Левон',
            surname: 'Яралян',
            profession: 'Android/ios разработчик'
        },
        {
            image: 'Lilit-removebg-preview.png',
            name: 'Лилит',
            surname: 'Мроян',
            profession: 'Android/ios разработчик'
        },
        {
            image: 'web1.png',
            name: 'Мане',
            surname: 'Геворгян',
            profession: 'Фронтенд разработчик'
        },
        {
            image: 'ani-removebg-preview.png',
            name: 'Ани',
            surname: 'Кошкарян',
            profession: 'Фронтенд разработчик'
        },
        {
            image: 'Arman-removebg-preview.png',
            name: 'Арман ',
            surname: 'Алекян',
            profession: 'Laravel разработчик'
        },
        {
            image: 'Karen-removebg-preview.png',
            name: 'Карен',
            surname: 'Ароян',
            profession: 'Laravel разработчик'
        },
    ]

    return (
        <div className='ourTeam'>
            <h2>НАША КОМАНДА</h2>
            <p>Кадры решают все! Спасибо всем, кто помогает решать клиентские задачи, развивать <br />
                агентство и делать все, чтобы наши заказчики оставались довольны!
            </p>
            <div className='teamMembers'>
                {team.map((e, i) => (
                    <div className='eachTeamMember' key={i}>
                        <div className='eachTeamImg'>
                            <img alt='' src={require(`../../../../public/${e.image}`)} />
                        </div>
                        <div className='teamNames'>
                            <h3>{e.name}</h3>
                            <h3>{e.surname}</h3>
                        </div>
                        <span>{e.profession}</span>
                    </div>
                ))}
            </div>
        </div>
    )
}