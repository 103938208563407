import './BannerScreen.scss'
import { VK, Telegram, Whatsapp } from '../../svg'
import { Link } from 'react-router-dom'

export default function App() {
    return (
        <div className='banner' >
            <div className='banner_container' >
                <div className='welcome_block'>
                    <h1>РАЗРАБОТКА САЙТОВ <br />
                        И МОБИЛЬНЫХ ПРИЛОЖЕНИЙ
                    </h1>
                    <p className='type'>Начиная с 2018 года, мы накопили значительный опыт в разработке <br />
                        веб-сайтов и мобильных приложений, а также в продвижении <br />
                        бизнесов.
                    </p>
                    <div className='welcoming'>
                        <div>
                            <span>Артем Акобджанян</span>
                            <p>Мобильный разработчик <br />
                                с командой
                            </p>
                        </div>
                        <div className='icons_block' >
                            <Link href="" target="_blank" rel="nofollow">{VK()}</Link>
                            <Link href="" target="_blank" rel="nofollow">{Telegram()}</Link>
                            <Link href="whatsapp://send?phone=+37493217779" target="_blank" rel="nofollow">{Whatsapp()}</Link>
                        </div>
                    </div>
                    <div className='btn_divs' >
                        <button>Получить консультацию</button>
                        <button className='transparent_btn' >+374 (77) 69-56-77</button>
                    </div>
                </div>
                <div className='pictures_block' >
                    <img className='front_img' alt='' src='/images/art2-removebg-preview.png' />
                </div>
            </div>
        </div>
    )
}