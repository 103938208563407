
export const GoogleMaps = () => {

  return (
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3048.6397856282138!2d44.50863537647397!3d40.172573170462634!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x406abdbd86faeca3%3A0x90233b43f9aaa717!2sJustCode%20Development%20Company!5e0!3m2!1sru!2sam!4v1685013481979!5m2!1sru!2sam"
      width="100%"
      height="400"
      title='map'
      style={{
        border: '0',
        allowfullscreen: "",
        loading: "lazy",
        referrerpolicy: "no-referrer-when-downgrade"
      }}
    />
  )
}