import './HelpsBlock.scss'
import { ContextAd } from '../../../svg'

const data = [
    {
        id: 1,
        img: ContextAd(),
        name: 'Разработка мобильных приложений',
        desc: 'Разработка мобильных приложений: создание качественных и интуитивно понятных мобильных приложений для iOS и Android, удовлетворяющих потребностям вашего бизнеса.'
    },
    {
        id: 2,
        img: ContextAd(),
        name: 'Разработка сайтов любой сложности',
        desc: 'Разработка сайтов любой сложности: создание уникальных и функциональных веб-сайтов, адаптивных под различные устройства, для достижения ваших целей в интернете.'
    },
    {
        id: 3,
        img: ContextAd(),
        name: 'SEO-продвижение',
        desc: 'Оптимизация сайта и контента для повышения его видимости в поисковых системах, привлечения целевой аудитории и увеличения органического трафика.'
    },
    {
        id: 4,
        img: ContextAd(),
        name: 'Контекстная реклама',
        desc: 'Cоздание и управление рекламными кампаниями с точечным показом рекламы в соответствии с интересами и запросами целевой аудитории.'
    },
    {
        id: 5,
        img: ContextAd(),
        name: 'Техподдержка, ускорение сайтов и др.',
        desc: 'JustCode: экспертная техподдержка, оптимизация и ускорение сайтов, решение технических проблем для эффективной и бесперебойной работы вашего онлайн-проекта.'
    },
    {
        id: 6,
        img: ContextAd(),
        name: 'Интеграция собственной CRM',
        desc: 'JustCode: интеграция собственной CRM для управления клиентскими данными и процессами, повышение эффективности работы и улучшение взаимодействия с клиентами.'
    },
]

export default function App() {
    return (
        <div className='helps_container' >
            <h2 >
                ПОМОГАЕМ БИЗНЕСУ <span style={{ color: '#FF6501' }}>СТАТЬ ЗАМЕТНЕЕ И ЗАМЕЧАТЕЛЬНЕЕ </span> <br /> В ИНТЕРНЕТЕ!
            </h2>
            <div className='helps_blocks_container' >
                {data.map((value, index) => {
                    return (
                        <div key={index} className='helps_block' >
                            {value.img}
                            <p>{value.name}</p>
                            <span>{value.desc}</span>
                        </div>
                    )
                })}

            </div>
        </div>
    )
}