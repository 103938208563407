import './style.css'
import { Link } from 'react-router-dom'

export const Websites = () => {
    const data = [
        {
            title: 'JustEarn',
            url: 'https://mos-moto.ru/',
            img: 'web1.png'
        },
        {
            title: 'MOS-MOTO.RU',
            url: 'https://mos-moto.ru/',
            img: 'mos-moto.jpg'
        },
        {
            title: 'MOS-MOTO.RU',
            url: 'https://mos-moto.ru/',
            img: 'mos-moto.jpg'
        },
        {
            title: 'MOS-MOTO.RU',
            url: 'https://mos-moto.ru/',
            img: 'mos-moto.jpg'
        },
    ]

    return (
        <div className="websites">
            <h2>WEB САЙТЫ</h2>
            <div className='websiteContainer'>
                {data.map((e, i) => (
                    <div className='eachWebsiteContainer' key={i}>
                        <Link to={e.url}><h6>{e.title}</h6></Link>
                        <img alt='' src={require(`../../../../public/${e.img}`)} />
                    </div>
                ))}
            </div>
        </div>
    )
}