import './style.css'

export const TrustUs = () => {
    return (
        <div className='trustUs'>
            <div className='maxTrust'>
                <img alt='' src={require('../../../../public/pattern_blue_1.png')} className='bluePattern1' />
                <img alt='' src={require('../../../../public/art2-removebg-preview.png')} className='trustImg' />
                <div className='trustText'>
                    <p>ДОВЕРЬТЕ НАМ ВСЕ ЗАДАЧИ И СМЕЛО <br />
                        СОСРЕДОТОЧЬТЕСЬ НА СВОЕМ БИЗНЕСЕ, <br />
                        ОСТАЛЬНОЕ МЫ ВОЗЬМЕМ НА СЕБЯ!
                    </p>
                    <button>Оставить заявку</button>
                </div>
            </div>
        </div>
    )
}