import './HomeScreen.scss'
import HeaderBlock from '../../components/home/header/HeaderBlock'
import MainBlock from '../../components/home/main/MainBlock'


export default function App({ }) {
    return (
        <div>
            <HeaderBlock />
            <MainBlock />
        </div>
    )
}