import './style.css'

export const JoinUs = () => {
    return (
        <div className='trustUs'>
            <div className='maxTrust'>
                <img alt='' src={require('../../../../public/pattern_blue_1.png')} className='bluePattern1' />
                <img alt='' src={require('../../../../public/Group-17.png')} className='joinImg' />
                <div className='joinText trustText'>
                    <p>ВЫ ОПЫТНЫЙ, ЧЕСТНЫЙ, ОТВЕТСТВЕННЫЙ,<br/> НЕ ЛЕНИВЫЙ <span>И ХОТИТЕ К НАМ В КОМАНДУ?</span></p>
                    <button>Ждем вас</button>
                </div>
            </div>
        </div>
    )
}